<template>
  <div class="queue-item-root-container">
    <div class="queue-item-left-container">
      <div class="queue-item-row" style="margin-bottom: 10px">
        <!-- <my-avatar :size="30" :name="dataSource.projectName" :img_url="dataSource.projectImgUrl"></my-avatar> -->
        <span style="font-size: 12px; font-weight: bold">{{
          dataSource.name
        }}</span>
      </div>
      <div class="queue-item-row" style="margin-bottom: 10px;font-size: 12px;" v-if="dataSource.third_voucher">
        <span>流水号：</span>
        <input :id="dataSource.third_voucher" style="width: 150px;border:none" :value="dataSource.third_voucher" />
        <span class="btn" style="margin-left: 15px" @click.stop="copy">点击复制</span>
      </div>
      <div class="queue-item-row" style="margin-bottom: 10px">
        <span>包含</span>
        <span class="queue-item-plan" v-for="item in dataSource.pay_plan_id_arr" :key="item">#{{ item }}#</span>
        <span>等{{ dataSource.pay_plan_id_arr.length }}个付款计划</span>
      </div>
      <queue-item-card :data-source="card" />
      <span style="margin-top: 10px"
        v-if="dataSource.fail_list && dataSource.fail_list.length > 0">包含{{ dataSource.fail_list.length }}笔失败</span>
      <div class="queue-item-fail-list">
        <Poptip v-for="(item, index) in dataSource.fail_list" :key="index" trigger="hover" title="失败金额"
          :content="item.amount">
          <span
            style="display: inline-block;margin: 10px;border-radius: 4px;background-color: #debfbf;padding: 5px 10px">{{ item.client_user_name }}</span>
        </Poptip>
      </div>
    </div>
    <div class="queue-item-right-container">
      <span v-if="dataSource.type == 2 || dataSource.state == 3" style="font-size: 12px">
        <Icon type="ios-time-outline" size="14" style="margin-right: 5px" />
        于{{ dataSource.pay_time }}自动支付
      </span>
      <span v-if="dataSource.state == 4" style="font-size: 12px">
        <Icon type="ios-alert" color="red" size="14" style="margin-right: 5px" />
        于{{ dataSource.pay_time }}付款失败
      </span>
      <div v-if="dataSource.type != 2 && dataSource.state == 1" class="queue-item-right-container">
        <span style="font-size: 12px">
          <Icon type="ios-alert" color="red" size="14" style="margin-right: 5px" />因资产不足而临时保存的任务
        </span>
        <Button type="primary" size="small" style="margin: 5px 0" @click.stop="pay">再次发起支付</Button>
      </div>
      <div v-if="dataSource.type != 2 && dataSource.state == 2" class="queue-item-right-container">
        <span style="font-size: 12px">
          <Icon type="ios-alert" color="red" size="14" style="margin-right: 5px" />银行处理中
        </span>
      </div>
      <span v-if="dataSource.state == 1 && !canPay" style="font-size: 12px; color: #b88585">当前的账户资产不足</span>

      <span v-if="dataSource.state == 5" style="font-size: 12px; color: #b88585">待发送到队列服务</span>

      <span v-if="dataSource.state == 6" style="font-size: 12px; color: #b88585">已发送到队列服务</span>

      <span v-if="dataSource.state == 7" style="font-size: 12px; color: #b88585">发送到队列服务失败</span>

      <div
        v-if="dataSource.state == 1 || dataSource.state == 2 || dataSource.state == 5 || dataSource.state == 6 || dataSource.state == 7"
        class="queue-item-row" style="margin-top: 10px">
        <span style="font-size: 12px; margin-right: 10px">全部{{ dataSource.count }}笔，金额</span>
        <fee :amount="dataSource.salary_amount" :primarySize="12" :secondarySize="12" />
      </div>
      <div v-if="dataSource.state == 3 || dataSource.state == 4" class="queue-item-row" style="margin-top: 10px">
        <span style="font-size: 12px; margin-right: 10px">成功{{ dataSource.count }}笔，金额</span>
        <fee :amount="dataSource.salary_amount" :primarySize="12" :secondarySize="12" />
      </div>

      <div class="queue-item-row" style="margin-top: 10px">
        <span style="font-size: 12px; margin-right: 10px">服务费</span>
        <fee :amount="dataSource.service_amount" :primarySize="12" :secondarySize="12" />
      </div>

      <Divider />

      <span style="font-weight: bold">合计</span>
      <fee :amount="totalAmount" :primarySize="23" :secondarySize="20" />

      <Divider />

      <div class="row" v-if="dataSource.state == 3">
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small"
          @click.stop="sendSBYZip">{{ "上传佐证材料" + sendStateStr }}</Button>
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small"
          @click.stop="sendSBYExcel">{{ "上传结算清单" + excelSendStateStr }}</Button>
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small" @click.stop="sendSBYBankPdf"
          :loading="sendSBYBankPdfLoading">{{ "上传银行凭证" + bankPdfStateStr }}</Button>
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small" @click.stop="createZip"
          :loading="isLoadingMaterial">{{ "生成佐证材料" + zipStateStr }}</Button>
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small" @click.stop="createPersonZip"
          :loading="isLoadingPersonMaterial">{{ "生成个人佐证材料" + zipPersonalStateStr }}</Button>
        <Button style="margin-top: 20px; margin-right: 20px" type="primary" size="small" @click.stop="createExcel"
          :loading="isLoadingList">{{ "生成结算清单" + excelStateStr }}</Button>
        <Button style="margin-top: 20px" type="primary" size="small" @click.stop="getSBYFileUrl">下载</Button>
      </div>

      <template v-if="isShowUrl">
        <Divider />

        <div class="row" style="justify-content: space-around">
          <a :href="file_url" @click.stop.capture style="margin-right: 20px">点击下载佐证材料</a>
          <a :href="person_file_url" @click.stop.capture>点击下载个人佐证材料</a>
        </div>
        <div class="row" style="justify-content: space-around">
          <a :href="detail_url" @click.stop.capture style="margin-right: 20px">点击下载结算清单</a>
          <a :href="abstract_url" @click.stop.capture>点击下载业务摘要</a>
        </div>
      </template>
    </div>
    <can-not-pay-modal ref="canNotPayModal" />
    <can-pay-modal ref="canPayModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import QueueItemCard from "./QueueItemCard";
import CanPayModal from "../Modal/CanPayModal";
import CanNotPayModal from "../Modal/CanNotPayModal";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "queue-item-card": QueueItemCard,
    "my-avatar": MyAvatar,
    "can-not-pay-modal": CanNotPayModal,
    "can-pay-modal": CanPayModal,
  },
  data() {
    return {
      // zip_state: 0, // 0 未生成 1 生成中 2 已生成
      // send_state: 0, // -2=上传结算单失败 -1=上传zip失败 0=未上传 1=上传中 2=已上传
      // excel_send_state: 0, // 0 未生成 1 生成中 2 已生成
      // excel_state
      file_url: "",
      detail_url: "",
      abstract_url: "",
      person_file_url: "",
      isShowUrl: false,
      isLoadingMaterial: false,
      isLoadingPersonMaterial: false,
      isLoadingList: false,
      sendSBYBankPdfLoading: false,

      card: {
        name: "",
        company_name: "",
        issuer: "",
        img_url: null,
        frozen_amount: 0,
        salary_amount: 0,
        service_amount: 0,
      },
    };
  },
  mounted() {
    this.assetGetCardDetailAction({
      employer_card_id: this.dataSource.employer_card_id,
      company_id: this.dataSource.company_id,
    })
      .then((res) => {
        this.card = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });

    // this.assetGetBalanceByCompanyIdAction({
    //   employer_id: localStorage.getItem("employer_id"),
    //   company_id: this.companyId,
    // })
    //   .then((res) => {
    //     this.card = res;
    //   })
    //   .catch((error) => {
    //     this.$Message.error(error);
    //   });
  },
  computed: {
    totalAmount() {
      return this.dataSource.salary_amount + this.dataSource.service_amount;
    },
    canPay() {
      return this.card.salary_amount >= this.dataSource.salary_amount;
    },
    zipStateStr() {
      switch (this.dataSource.zip_state) {
        case 1:
          return "（生成中）";
        case 2:
          return "（已生成）";
        default:
          return "";
      }
    },
    zipPersonalStateStr() {
      switch (this.dataSource.person_zip_state) {
        case 1:
          return "（生成中）";
        case 2:
          return "（已生成）";
        default:
          return "";
      }
    },
    excelStateStr() {
      switch (this.dataSource.excel_state) {
        case 2:
          return "（已生成）";
        default:
          return "";
      }
    },
    excelSendStateStr() {
      switch (this.dataSource.excel_send_state) {
        case 1:
          return "（上传中）";
        case 2:
          return "（已上传）";
        default:
          return "";
      }
    },
    bankPdfStateStr() {
      switch (this.dataSource.bank_pdf_state) {
        case 1:
          return "（上传中）";
        case 2:
          return "（已上传）";
        default:
          return "";
      }
    },
    sendStateStr() {
      switch (this.dataSource.send_state) {
        case -2:
          return "（上传结算单失败）";
        case -1:
          return "（上传zip失败）";
        case 0:
          return "";
        case 1:
          return "（上传中）";
        case 2:
          return "（已上传）";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions({
      queueSendSBYZipAction: "queueSendSBYZip",
      queueCreateSBYZipAction: "queueCreateSBYZip",
      queueSendSBYExcelAction: "queueSendSBYExcel",
      queueGetSBYFileUrlAction: "queueGetSBYFileUrl",
      assetGetCardDetailAction: "assetGetCardDetail",
      queueCreateSBYExcelAction: "queueCreateSBYExcel",
      queueSendSBYBankPdfAction: "queueSendSBYBankPdf",
      queueCreateSBYPersonZipAction: "queueCreateSBYPersonZip",
      assetGetBalanceByCompanyIdAction: "assetGetBalanceByCompanyId",
    }),
    pay() {
      if (this.canPay) {
        this.$refs.canPayModal.open(this.dataSource);
      } else {
        this.$refs.canNotPayModal.open(this.dataSource.queue_id);
      }
    },
    sendSBYZip() {
      this.queueSendSBYZipAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.$Message.success("上传罗格成功");
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        })
        .finally(() => {
          this.$emit("refresh");
        });
    },
    sendSBYExcel() {
      this.queueSendSBYExcelAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.$Message.success("上传罗格成功");
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        })
        .finally(() => {
          this.$emit("refresh");
        });
    },
    createZip() {
      if (this.isLoadingMaterial) {
        return;
      }
      this.dataSource.zip_state = 1;
      this.isLoadingMaterial = true;
      this.queueCreateSBYZipAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.dataSource.zip_state = 2;
          this.isShowUrl = false;
          this.isLoadingMaterial = false;
          this.$Message.success("生成成功");
        })
        .catch((error) => {
          this.dataSource.zip_state = 0;
          this.isLoadingMaterial = false;
          this.$Message.error(error);
        });
    },
    createPersonZip() {
      if (this.isLoadingPersonMaterial) {
        return;
      }
      this.dataSource.person_zip_state = 1;
      this.isLoadingPersonMaterial = true;
      this.queueCreateSBYPersonZipAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.dataSource.person_zip_state = 2;
          this.isShowUrl = false;
          this.isLoadingPersonMaterial = false;
          this.$Message.success("生成成功");
        })
        .catch((error) => {
          this.dataSource.person_zip_state = 0;
          this.isLoadingPersonMaterial = false;
          this.$Message.error(error);
        });
    },
    createExcel() {
      if (this.isLoadingList) {
        return;
      }

      this.dataSource.excel_state = 1;
      this.isLoadingList = true;
      this.queueCreateSBYExcelAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.dataSource.excel_state = 2;
          this.isShowUrl = false;
          this.isLoadingList = false;
          this.$Message.success("生成成功");
        })
        .catch((error) => {
          this.isLoadingList = false;
          console.error(error);
          this.$Message.error(error);
        });
    },
    getSBYFileUrl() {
      this.queueGetSBYFileUrlAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.file_url = res.file_url;
          this.detail_url = res.detail_url;
          this.abstract_url = res.abstract_url;
          this.person_file_url = res.person_file_url;
          this.isShowUrl = true;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    copy() {
      navigator.clipboard.writeText(this.dataSource.third_voucher);
      this.$Message.success("复制成功");
    },
    sendSBYBankPdf() {
      if (this.sendSBYBankPdfLoading) {
        return;
      }
      this.sendSBYBankPdfLoading = true;
      this.queueSendSBYBankPdfAction({
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          this.$Message.success("上传银行凭证成功");
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        })
        .finally(() => {
          this.sendSBYBankPdfLoading = false;
          this.$emit("refresh");
        });
    },
  },
};
</script>

<style scoped>
.queue-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  transition: all 0.2s ease;
  padding: 10px 20px;
  box-shadow: 0px 0px 1px #dddddd;
  cursor: pointer;
}
.queue-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.queue-item-left-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
}
.queue-item-right-container {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.queue-item-plan {
  font-weight: bold;
  font-size: 15px;
  color: #82a3c4;
  margin: 0 10px;
  cursor: pointer;
}
.queue-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.queue-item-fail-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>